import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Checkup from "../../components/checkup"
import { Link } from "gatsby"

import OtherCaseStudy from "../../components/other-case"

import simay from "../../assets/img/teams/08.jpg"
import burcu from "../../assets/img/teams/26.jpg"
import bengu from "../../assets/img/teams/17.jpg"

import mockup from "../../assets/img/enerjisa-mockup.png"


const ReferancesDetail = () => (
    <Layout>
        <SEO title="Enerjisa Başarı Hikayesi" />
        <div className="referances-detail py60 container">
            <div className="col-md-5 detail-left">
                <b>BAŞARI HİKAYESİ</b>
                <h1 style={{ margin: '20px auto 20px' }}>Enerjisa</h1>
                <p style={{ maxWidth: 400}}>EnerjiSA; Türkiye’nin enerjisi olarak, Başkent, Ayedaş ve Toroslar olmak üzere faaliyet gösterdiği 3 dağıtım bölgesi ile 10.1 milyon müşterisine 14 ilde ulaşarak, yaklaşık 21.5 milyonu aşkın kullanıcıya hizmet sağlıyor.</p>
               
                
            </div>
            <div className="col-md-7 referances-boxs">
                <div className="provided">
                    <h3 >Verilen Hizmetler</h3>
                    <div className="provided-box">
                        <div className="box">
                            <svg style={{ marginLeft: -7 }} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 79 80">
                                <g id="Icons-_-Shopping-_-Chart-line_1" data-name="Icons-/-Shopping-/-Chart-line#1" transform="translate(-0.462 0.373)">
                                    <rect id="bound" width="79" height="80" transform="translate(0.462 -0.373)" fill="none" />
                                    <path id="Path-95" d="M9.6,55.773H59.071a3.3,3.3,0,0,1,0,6.6H6.3a3.3,3.3,0,0,1-3.3-3.3V6.3a3.3,3.3,0,0,1,6.6,0Z" transform="translate(6.895 7.094)" fill="#000000" />
                                    <path id="Path-97" d="M12.7,33.493a3.3,3.3,0,0,1-4.812-4.512L20.261,15.788a3.3,3.3,0,0,1,4.547-.253l9.762,8.33L47.289,7.755a3.3,3.3,0,1,1,5.178,4.088l-14.842,18.8a3.3,3.3,0,0,1-4.73.465L22.92,22.6Z" transform="translate(16.088 15.138)" fill="#000000" opacity="0.3" />
                                </g>
                            </svg>
                            SEO
                        </div>
                    </div>
                </div>
                <div className="project-details">
                    <h3 style={{ margin: '1px 0' }}>Künye</h3>
                    <div className="project-details_list">
                        <ul>
                            <li>Marka Stratejisti:</li>
                            <li>Marka Yöneticisi:</li>
                            <li>SEO</li>
                          
                        </ul>
                        <ul>
                            <li><img src={simay} />Simay Akkurt</li>
                            <li><img src={burcu} />Burcu Okurgan</li>
                            <li><img src={bengu} />Bengü Dinçer</li>
                           
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div className="container">
            <div style={{ margin: '10px auto 50px' }} className="text-center">
                <p style={{ fontSize: 18, margin: '0 auto 25px', maxWidth: 800 }} >Flatart olarak EnerjiSA markasının online kullanıcı ile buluşturulması ve kullanıcıların yoğun olarak arama yaptığı anahtar kelimelerde ilk sıralarda yer alma fayda odağıyla, SEO ve Dijital Pazarlama Danışmanlığı Hizmetini 3 web sitesi üzerinde sürdürmekteyiz.</p>
                <a href="http://baskentedas.com.tr"><p style={{ fontSize: 18, margin: '0 auto', maxWidth: 800 }} ><b>http://baskentedas.com.tr</b></p></a>
                <a href="https://www.ayedas.com.tr"><p style={{ fontSize: 18, margin: '0 auto', maxWidth: 800 }} ><b>https://www.ayedas.com.tr</b></p></a>
                <a href="https://www.toroslaredas.com.tr"><p style={{ fontSize: 18, margin: '0 auto', maxWidth: 800 }} ><b>https://www.toroslaredas.com.tr</b></p></a>
                <img className="enerjisa-img" src={mockup} />
            </div>
            
            <div style={{ margin: '20px auto 50px' }} className="text-center">
                <p  style={{color:"#ea4f6a", fontSize: 20,  fontWeight:600}} >Başarı Hikayesi</p>
            </div>
            <div className="referances-detail container" > 
            <div className="col-md-1">
            </div>
            <div className="col-md-10 referances-boxs">
                <div className="boxs" style={{ marginBottom: '0' }}>
                    <div className="box enerjisa-kutu" style={{ width:250}} >
                        <span style={{fontSize:25, fontWeight:700}}>8.2K</span>
                        <p style={{fontWeight:100}}>AYEDAŞ’ın ilk 100 sıralamada yer alan aylık ortalama anahtar kelime sayısı</p>
                    </div>
                    <div className="box enerjisa-kutu" style={{ width:250}}>
                        <span style={{fontSize:25, fontWeight:700}}>6.5K</span>
                        <p style={{fontWeight:100}}>BAŞKENT EDAŞ’ın ilk 100 sıralamada yer alan aylık ortalama anahtar kelime sayısı</p>
                    </div>
                    <div className="box enerjisa-kutu" style={{ width:250}}>
                        <span style={{fontSize:25, fontWeight:700}}>6.3K</span>
                        <p style={{fontWeight:100}}>TOROSLAR EDAŞ’ın ilk 100 sıralamada yer alan aylık ortalama anahtar kelime sayısı</p>
                    </div>
                </div>
            </div>
            <div className="col-md-1">   
            </div>
        </div>
        <div style={{ margin: '50px auto 50px' }} className="text-center">
                <h2>Başlıca Anahtar Kelimeler</h2>
                <p style={{ fontSize: 18, margin: '7px auto 10px', maxWidth: 800 }} >Trafik Sayısına Göre; Toroslar Elektrik, Başkent Elektrik, Elektrik Kesintisi, Ankara Elektrik Kesintisi, Elektrik Arıza, Planlı Kesinti</p>
            </div>
        </div>
        
           
        <OtherCaseStudy />

        <Checkup />
    </Layout >
)

export default ReferancesDetail
